<template>
    <div class="main-nologin">

        <div class="page-header">
            <div class="logo">
                <img class="logo-img" src="@/assets/header/logo.png" alt="capgemini" />
            </div>
        </div>
        <template v-if="serialNo === 'consultantEvaluation' || serialNo === 'assignmentExpirationEmail' || errorFlg">
            <div class="overTimeContiner">
                <div>
                    <div style="display:flex;align-items:center;justify-content:center">
                        <img :src="errGif" class="img" alt="Girl has dropped her ice cream." />
                    </div>
                    <h3 v-show="showEmail" class="title">新邮件发送成功，请到邮箱查收</h3>
                    <h4 v-show="errorFlg && showErrorTitle" class="title">
                        链接超时，请<span @click="emailAgain" style="color: #3182CE;cursor:pointer">点击此处</span>触发新的邮件和链接
                    </h4>
                </div>
            </div>

        </template>
        <template v-else>
            <div class="open-page">
                <div class="tag">
                    <a-tag>修改调令时间</a-tag>
                </div>
            </div>
            <div class="container">
                <div class="tab">
                    <a-tabs v-model:activeKey="activeTab" defaultActiveKey="1" class="left" v-if="isEvaluation">
                        <a-tab-pane key="1" tab="已完成 Completed"></a-tab-pane>
                    </a-tabs>
                    <a-tabs v-model:activeKey="activeTab" defaultActiveKey="1" class="left" v-else>
                        <a-tab-pane key="1" tab="进行中 In Progress"></a-tab-pane>
                    </a-tabs>
                </div>
                <a-page-header class="header">
                    <template #title>
                        <span>Account: {{ assignmentCondition.account }}</span>
                        <span>Code: {{ assignmentCondition.engagementCode }}</span>
                        <span>
                            Engagement Description:
                            <a-tooltip placement="top" :title="assignmentCondition.engagementDescription">{{
                                assignmentCondition.engagementDescription }}</a-tooltip>
                        </span>
                    </template>
                </a-page-header>
                <section class="assignment-list">
                    <a-table :columns="columns" :data-source="assDetails" :pagination="pagination" row-key="id"
                        :rowClassName="rowClassNameFunc" :customRow="customRowFunc" :scroll="{
                            x: tableWidth,
                            y: tableHeight,
                            scrollToFirstRowOnChange: true,
                        }">
                    </a-table>
                    <AssignmentInfo :visible="infoVisible" :nonLoginEvalute="isEvaluation" :orgInfo="activeRow"
                        :active-tab="activeTab" :annexColumns="annexColumns" @close-info="closeInfo"
                        @open-appraises="openAppraises" :noReleaseDate="!isEvaluation"
                        @pm-estimate-confirm="pmEstimateConfirm" />
                    <FillAppraises v-model:visible="appraisesVisible" title="Assignment Review" :form-state="formState"
                        @close-modal="closeAppraises" @submit-modal="submitAppraises" />
                    <EstimateConfirm v-model:visible="estimateConfirmVisible" title="Assignment Estimate Date 时间确定"
                        v-model:formState="estimateConfirmFormState" v-model:codeTermData="codeTermData" :is-rm="false"
                        @close-modal="closeEstimateConfirm" @submit-modal="submitEstimateConfirm" />
                    <OverlapModal :data="overlapData" @confirm="timeJudgmentSubmitFunc"
                        v-model:visible="overlapModalVisible" />
                </section>

            </div>
        </template>


    </div>
</template>
<script>
import { defineComponent, computed, onMounted, ref, onUnmounted, nextTick } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { useRoute } from "vue-router";
import { formatTableCol, useTableHeight } from "@/utils/common";
import { assignmentStatus } from "@/utils/formatStatus";
import { message } from "ant-design-vue";
import moment from "moment";
import AssignmentInfo from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/AssignmentInfo";
import FillAppraises from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/FillAppraises";
import EstimateConfirm from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/EstimateConfirm.vue";
import OverlapModal from "@/components/OverlapModal.vue";
import errGif from "@/assets/NoLogin/nologin.png";
export default defineComponent({
    components: {
        OverlapModal,
        EstimateConfirm,
        AssignmentInfo,
        FillAppraises
    },
    data() {
        return {
            errGif: errGif + "?" + +new Date(),
        };
    },
    setup() {

        const getSize = () => {
            nextTick(() => {
                const bodyWidth = document.body.clientWidth;
                tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
                tableHeight.value = useTableHeight(true, ".ant-table", "", "", 60);
            });
        };

        window.addEventListener("resize", getSize);

        onMounted(() => {
            isEvaluation.value ? getEvaluation() : getExpiration()
            getSize();
        });

        onUnmounted(() => {
            window.removeEventListener("resize", getSize);
        });

        const { $api } = useGlobalPropertyHook();

        let assignmentCondition = ref({
            account: null,
            engagementCode: null,
            engagementDescription: null
        })
        const route = useRoute();
        let errorFlg = ref(false)
        let showErrorTitle = ref(false)

        const serialNo = computed(() => route.params.id)

        const assDetails = ref();
        const showEmail = ref(false)

        const getEvaluation = async (pageSize = 10, pageIndex = 1) => {
            if (serialNo.value == 'consultantEvaluation') {
                let res = await $api.anotherGetPMEvaluationInfo({ clientCode: route.query.clientCode })
                if (res.code == 200) {
                    showEmail.value = true
                }
            } else {
                let res = await $api.getPMEvaluationInfo(serialNo.value, { pageSize: pageSize, pageIndex: pageIndex })
                if (res.msg === '链接失效') {
                    errorFlg.value = true
                    showErrorTitle.value = true
                } else {
                    let list = res.data.detailPmPage.resource
                    if (list.length) {
                        infoVisible.value = true;
                        activeRow.value = list[0];
                        assignmentCondition.value.account = list[0].account
                        assignmentCondition.value.engagementCode = list[0].engagementCode
                        assignmentCondition.value.engagementDescription = list[0].engagementDescription
                        assDetails.value = list
                    } else {
                        infoVisible.value = false;
                        activeRow.value = [];
                        assignmentCondition.value.account = ''
                        assignmentCondition.value.engagementCode = ''
                        assignmentCondition.value.engagementDescription = ''
                        assDetails.value = []
                    }
                }
            }
        }
        const getExpiration = async (pageSize = 10, pageIndex = 1) => {
            if (serialNo.value == 'assignmentExpirationEmail') {
                let res = await $api.anotherGetFinal(route.query.code)
                if (res.code == 200) {
                    showEmail.value = true
                }
            } else {

                let res = await $api.getPMExpirationInfo(serialNo.value, { pageSize: pageSize, pageIndex: pageIndex })
                if (res.msg === '链接失效') {
                    errorFlg.value = true
                    showErrorTitle.value = true
                } else {
                    let list = res.data.detailPmPage.resource
                    if (list.length) {
                        infoVisible.value = true;
                        activeRow.value = list[0];
                        assignmentCondition.value.account = list[0].account
                        assignmentCondition.value.engagementCode = list[0].engagementCode
                        assignmentCondition.value.engagementDescription = list[0].engagementDescription
                        assDetails.value = list
                    }
                }
            }
        }
        const emailAgain = async () => {
            if (isEvaluation.value) {
                let code
                if (route.query.clientCode) {
                    code = route.query.clientCode
                } else {
                    code = serialNo.value
                }
                let res = await $api.anotherGetPMEvaluationInfo({ clientCode: code })
                if (res.code == 200) {
                    showEmail.value = true
                    showErrorTitle.value = false
                }
            } else {
                let code
                if (route.query.code) {
                    code = route.query.code
                } else {
                    code = serialNo.value
                }
                let res = await $api.anotherGetFinal(code)
                if (res.code == 200) {
                    showEmail.value = true
                    showErrorTitle.value = false
                }
            }
        }

        let activeTab = "1"

        const activeRow = ref({
            id: "",
        });

        const customRowFunc = (record) => {
            return {
                onClick: () => {
                    activeRow.value = record;
                    infoVisible.value = true;
                },
            };
        };

        const columns = [
            {
                title: "Candidate Name",
                dataIndex: "fullName",
                key: "fullName",
                width: 130,
                fixed: "left",
                ellipsis: true,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                customRender: formatTableCol(assignmentStatus),
                width: 100,
                fixed: "left",
                ellipsis: true,
            },
            {
                title: "PM",
                dataIndex: "pmInfo",
                key: "pmInfo",
                width: 240,
                ellipsis: true,
                customRender: ({ text }) => (
                    <div>
                        <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
                        <div>{text ? text.split(" ")[2] : null}</div>
                    </div>

                ),
                customCell: () => {
                    return {
                        style: {
                            lineHeight: 1.2,
                            padding: '6px 10px',
                        }
                    }
                }
            },
            {
                title: "Grade",
                dataIndex: "psGroup",
                key: "psGroup",
                width: 80,
                ellipsis: true,
            },
            {
                title: "ADRC",
                dataIndex: "personCost",
                key: "personCost",
                width: 80,
                ellipsis: true,
            },
            {
                title: "Service Line",
                dataIndex: "serviceLine",
                key: "serviceLine",
                width: 150,
                ellipsis: true,
            },
            {
                title: "Sub Service Line",
                dataIndex: "subServiceLine",
                key: "subServiceLine",
                width: 180,
                ellipsis: true,
            },
            {
                title: "Project Onboard Date",
                dataIndex: "onBoardDate",
                key: "onBoardDate",
                width: 130,
                ellipsis: true,
            },
            {
                title: "Estimate Release Date",
                dataIndex: "estimateReleaseDate",
                key: "estimateReleaseDate",
                width: 180,
                ellipsis: true,
            },
            {
                title: "Hours Per Day",
                dataIndex: "hoursPerDay",
                key: "hoursPerDay",
                width: 130,
                ellipsis: true,
            },
        ];

        const pagination = ref({
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
            onChange: (page) => {
                pagination.value.current = page;
                isEvaluation.value ? getEvaluation(pagination.value.pageSize, pagination.value.current) : getExpiration(pagination.value.pageSize, pagination.value.current)
            },
            onShowSizeChange: (_, size) => {
                pagination.value.pageSize = size;
                pagination.value.current = 1;
                isEvaluation.value ? getEvaluation(pagination.value.pageSize, pagination.value.current) : getExpiration(pagination.value.pageSize, pagination.value.current)
            },
        });

        const tableHeight = ref(0);
        const tableWidth = ref(0);
        const infoVisible = ref(false);

        const annexColumns = [
            {
                title: "名称",
                dataIndex: "fileName",
                key: "fileName",
                width: 150,
                ellipsis: true,
            },
            {
                title: "时间",
                dataIndex: "operationTime",
                key: "operationTime",
                width: 150,
                ellipsis: true,
            },
            {
                title: "操作",
                dataIndex: "operation",
                slots: { customRender: "operation" },
                width: 100,
                fixed: "right",
            }
        ]

        const closeInfo = () => {
            activeRow.value = {
                id: "",
            };
            infoVisible.value = false;
        };

        const formState = ref({});
        const appraisesVisible = ref(false);

        const openAppraises = (record) => {
            $api.nonLogingetAppraises(serialNo.value, { assignmentId: record.id }).then((res) => {
                if (res.msg === '链接失效') {
                    errorFlg.value = true
                    showErrorTitle.value = true
                } else {
                    if (res.data) {
                        formState.value = res.data;
                        formState.value.attitude = parseInt(res.data.attitude);
                        formState.value.english = parseInt(res.data.english);
                        formState.value.skill = parseInt(res.data.skill);
                        formState.value.teamwork = parseInt(res.data.teamwork);
                        formState.value.syntheticalScore = parseInt(
                            res.data.syntheticalScore
                        );
                        formState.value.reviewDate = moment(res.data.reviewDate);
                    } else {
                        formState.value = {
                            assignmentId: record.id,
                            attitude: "",
                            skill: "",
                            english: "",
                            teamwork: "",
                            syntheticalScore: "",
                            keyTalent: "",
                            reviewDate: moment(),
                            retentionRisk: "",
                            summary: "",
                        };
                    }
                    appraisesVisible.value = true;
                }
            })

        };

        const closeAppraises = () => {
            formState.value = {};
            appraisesVisible.value = false;
        };
        const submitAppraises = async () => {
            formState.value.reviewDate = formState.value.reviewDate.format("YYYY-MM-DD")
            let res = await $api.nonLoginpmAppraises({ serialNo: serialNo.value, ...formState.value })
            if (res.msg === '链接失效') {
                errorFlg.value = true
                showErrorTitle.value = true
            } else {
                message.success(res.msg);
                getEvaluation(pagination.value.pageSize, pagination.value.current);
                closeAppraises();
            }
        };
        const rowClassNameFunc = (record) => {
            if (record.isDeleted) {
                if (record.id === activeRow.value.id) {
                    return "active-row deactivate-row";
                }
                return "deactivate-row";
            }
            if (record.id === activeRow.value.id) {
                return "active-row";
            }
            return null;
        };

        const isEvaluation = computed(() => route.name === 'evaluation')

        const estimateConfirmFormState = ref({});
        const estimateConfirmVisible = ref(false)
        const codeTermData = ref({
            projectStartDate: '',
            projectCloseDate: '',
        })
        const segmenteData = ref([])
        const pmEstimateConfirm = async () => {
            let id = activeRow.value.id
            let res = await $api.nonLoginGetChangeDateStatus(id)
            if (res.msg === '链接失效') {
                errorFlg.value = true
                showErrorTitle.value = true
            } else {
                Object.assign(estimateConfirmFormState.value, activeRow.value)
                estimateConfirmFormState.value.staffAssignmentParts = activeRow.value.staffAssignmentParts ? activeRow.value.staffAssignmentParts : []
                estimateConfirmFormState.value.actualDate = res.data.actualReleaseDate? res.data.actualReleaseDate : activeRow.value.actualReleaseDate
                estimateConfirmFormState.value.originalOnboardDate = activeRow.value.onBoardDate
                estimateConfirmFormState.value.originalActualDate = activeRow.value.actualReleaseDate
                codeTermData.value.projectStartDate = activeRow.value.codeStartDate
                codeTermData.value.projectCloseDate = activeRow.value.codeEndDate
                estimateConfirmVisible.value = true
            }
        }

        const closeEstimateConfirm = () => {
            estimateConfirmVisible.value = false
        }
        const submitEstimateConfirm = (e) => {
            const staffAssignment = {
                id: estimateConfirmFormState.value.id,
                hoursPerDay: estimateConfirmFormState.value.hoursPerDay,
                actualReleaseDate: estimateConfirmFormState.value.actualDate,// 替换
                onBoardDate: estimateConfirmFormState.value.onBoardDate,
                userId: estimateConfirmFormState.value.userId,
                staffAssignmentParts: e.staffAssignmentParts
            };
            if (e.staffAssignmentParts.length > 0) {
                staffAssignment.staffAssignmentParts = e.staffAssignmentParts.filter(item =>
                    moment(item.startDate).isBefore(estimateConfirmFormState.value.actualDate) || moment(item.startDate).isSame(estimateConfirmFormState.value.actualDate)
                ).map(item => {
                    return {
                        id: item.id,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        hoursPerDay: item.hoursPerDay
                    }
                })
                staffAssignment.staffAssignmentParts[staffAssignment.staffAssignmentParts.length - 1].endDate = estimateConfirmFormState.value.actualDate
                if (staffAssignment.staffAssignmentParts.length === 1) {
                    staffAssignment.hoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
                    estimateConfirmFormState.value.newHoursPerDay = staffAssignment.staffAssignmentParts[0].hoursPerDay
                    staffAssignment.staffAssignmentParts = []
                } else {
                    estimateConfirmFormState.value.newHoursPerDay = staffAssignment.hoursPerDay
                }
            }
            segmenteData.value = staffAssignment.staffAssignmentParts
            currentOverlapData.value = {
                clientName: activeRow.value.account,
                engagementCode: activeRow.value.engagementCode,
                engagementDescription: activeRow.value.engagementDescription,
            }
            localStorage.setItem('PreEightTimeLine',
                JSON.stringify([staffAssignment.onBoardDate, staffAssignment.actualReleaseDate])
            )
            $api.pmNoIsContinue(staffAssignment).then((res) => timeJudgmentsFunc(res))
        }

        //8小时校验
        const overlapModalVisible = ref(false);
        const overlapData = ref({
            status: "prohibit",
            assignmentList: [],
            workTaskList: [],
            overlapCommandList: [],
            currentformData: {},
            demandList: [],
        });
        const currentOverlapData = ref({
            clientName: '',
            engagementCode: '',
            engagementDescription: '',
        })
        const timeJudgmentsFunc = (res) => {
            if (res.msg === '链接失效') {
                errorFlg.value = true
                showErrorTitle.value = true
                return
            }
            if (res.code !== 200) {
                message.error(res.msg)
                return
            }
            if (res.data.gatherFlag === "false") {
                // 时间不重叠
                timeJudgmentSubmitFunc();
            } else {
                overlapData.value = {
                    status: res.data.gatherFlag,
                    assignmentList: res.data.assignmentQueryPartCommandList || [],
                    workTaskList: res.data.workTaskList || [],
                    overlapCommandList: res.data.overlapCommandList || [],
                    currentformData: currentOverlapData.value,
                    demandList: res.data.demandList || [],
                }
                overlapModalVisible.value = true;
            }
        };
        const timeJudgmentSubmitFunc = () => {
            overlapModalVisible.value = false;
            const result = {
                assignmentId: estimateConfirmFormState.value.id,
                actualDate: estimateConfirmFormState.value.actualDate,
                onBoardDate: "",
                reason: estimateConfirmFormState.value.applyReason,
                hoursPerDay: estimateConfirmFormState.value.newHoursPerDay,
                staffAssignmentParts: segmenteData.value,
                serialNo: serialNo.value,
            };
            $api.updateReleaseDate(result).then((res) => {
                if (res.msg === '链接失效') {
                    errorFlg.value = true
                    showErrorTitle.value = true
                    return
                }
                message.success("成功");
                estimateConfirmVisible.value = false
                getExpiration()
            })
        };
        return {
            activeTab,
            assignmentCondition,
            customRowFunc,
            pagination,
            assDetails,
            columns,
            tableHeight,
            tableWidth,
            infoVisible,
            annexColumns,
            activeRow,
            closeInfo,
            openAppraises,
            closeAppraises,
            appraisesVisible,
            formState,
            submitAppraises,
            rowClassNameFunc,
            isEvaluation,
            pmEstimateConfirm,
            estimateConfirmVisible,
            closeEstimateConfirm,
            submitEstimateConfirm,
            estimateConfirmFormState,
            codeTermData,
            overlapModalVisible,
            overlapData,
            currentOverlapData,
            timeJudgmentsFunc,
            timeJudgmentSubmitFunc,
            getExpiration,
            segmenteData,
            serialNo,
            showEmail,
            errorFlg,
            emailAgain,
            showErrorTitle
        }
    },
})
</script>

<style lang="less" scoped>
.main-nologin {
    .page-header {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 5px 14px rgba(24, 144, 255, 0.05);
        display: flex;

        .logo {
            // 宽度和菜单相同
            width: 350px;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-img {
                height: 43px;
                width: 172px;
                margin-top: 11px;
            }
        }
    }

    .overTimeContiner {
        width: 100vw;
        height: 92vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
            width: 388.99px;
            height: 326.04px;
        }

        .title {
            font-size: 44px;
            line-height: 66px;
            font-weight: 500;
            color: #ABABAB;
            padding: 14px 0 14px;
            margin: 0;
        }
    }


    .container {
        padding: 0 40px;
        //margin-top: 20px;

        .tab {}

        .header {
            margin: 0 20px;
            padding: 0;

            span {
                margin-right: 40px;
            }
        }
    }

}

.assignment-list {
    ::v-deep .ant-table-body {
        overflow: auto;
    }
}

.assignment-list {
    flex: 1;
    overflow: auto;
    display: flex;
    background-color: #fff;
    width: calc(100% - 40px);
    // height: calc(100% - 188px);
    position: relative;

    .ant-table-wrapper {
        flex: 1;
        width: 0;
    }


    .batch-btn {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}


:deep(.org-info) {
    width: 700px;
    height: 620px;
    //margin-left: 20px;
    background: #f4f4f4;
}

:deep(.ant-table-body) {
    .active-row {
        background: #e8f4ff !important;

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right,
        td {
            background: #e8f4ff !important;
        }
    }

    .deactivate-row {
        background: #f4f4f4 !important;

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right,
        td {
            background: #f4f4f4 !important;
        }
    }

    .deactivate-row.active-row {
        background: #e0e0e0 !important;

        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right,
        td {
            background: #e0e0e0 !important;
        }
    }
}

.open-page {
    height: 40px;
    display: flex;
    align-items: center;
    background: var(--unnamed, #FAFAFA);
    border-bottom: 0.1vw solid rgba(0, 0, 0, .08);

    .tag {
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: auto;
        margin-left: 20px;
    }

    .ant-tag {
        background: #fff;
        padding: 0.4vw 1.8vw 0.4vw 1.5vw;
        color: #303133;
        position: relative;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        border: 0 solid #d9d9d9;
        border-top: 0.10417vw solid #3182CE;
    }

    .active-page-tag {
        background: #ECF5FE;
        border-top: 0.10417vw solid #3182CE;
    }
}
</style>
